import React, { Component, Fragment } from "react";
import "./scss/app.scss";
import TopMenu from "./components/topmenu";
import LeftMenu from "./components/leftmenu";
import Container from "./pages/container";
// import Mobileorientation from "./components/mobileorientation";
import Home from "./pages/home";
import Microphone from "./pages/microphone";
import HowJoin from "./pages/howjoin";
import SelectVideo from "./pages/selectvideo";
import Join from "./pages/join";
import Jury from "./pages/jury";
import Term from "./pages/term";
import { ModalContainer } from "./components/modal";
let left;
let top;
let pages = [
  { text: "Yarışma" },
  { text: "Nasıl Katılırım" },
  { text: "Videonu Seç" },
  { text: "Hemen Katıl" },
  { text: "Jüri Üyelerimiz" },
  { text: "Katılım Koşulları" }
];
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: -1
    };
  }
  setPage = page => {
    if (page < -1) page = -1;
    if (page > pages.length - 1) page = pages.length - 1;
    if (isNaN(page)) return;
    this.setState({ page });
  };
  render() {
    return (
      <Fragment>
        {/* <Mobileorientation /> */}
        <ModalContainer />

        <div className="app">
          <TopMenu
            ref={ref => (top = ref)}
            page={this.state.page}
            onToggle={st => {
              left.toggle(st);
            }}
          />
          <LeftMenu
            ref={ref => (left = ref)}
            page={this.state.page}
            pages={pages}
            onPageChange={page => {
              this.setPage(page);
              top.toggle(false);
              left.toggle(false);
            }}
          />
          <Container
            page={this.state.page}
            pageSize={pages.length}
            onPageChangeRequest={value => {
              this.setPage(this.state.page + value);
            }}
          >
            <Home />
            <Microphone />
            <HowJoin />
            <SelectVideo />
            <Join />
            <Jury />
            <Term />
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default App;
