import React, { Component } from "react";
import classNames from "classnames";
import "../../scss/components/label-textbox.scss";
import randKey from "../../libs/randkey";
import DatePicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("tr", tr);
class LabelTexbox extends Component {
  constructor(props) {
    super(props);
    this.state = { top: props.value };
    this.id = this.props.id ? this.props.id : randKey();
  }
  control = () => {
    var oldS = this.state.top;
    var newS = this.state.focus || this.props.value;
    if (newS !== oldS) this.setState({ top: newS });
  };
  setFocus = (focused) => {
    this.setState({ focus: focused }, () => {
      this.control();
    });
  };
  componentWillReceiveProps(next) {
    this.control();
  }
  getLabel = () => {
    return !this.props.error || this.state.top
      ? this.props.label
      : this.props.error;
  };
  render() {
    return (
      <div
        className={classNames("label-textbox-container", this.props.className, {
          error: this.props.error,
        })}
      >
        <label
          className={classNames({ top: this.state.top })}
          htmlFor={this.id}
        >
          {this.getLabel()}
        </label>
        {this.props.type === "date" ? (
          <DatePicker
            showPopperArrow={false}
            selected={this.props.value}
            onChange={this.props.onChange}
            showYearDropdown
            maxDate={this.props.maxDate}
            openToDate={this.props.maxDate}
            showMonthDropdown
            onFocus={() => this.setFocus(true)}
            dateFormat={"dd.MM.yyyy"}
            locale={"tr"}
            onBlur={() => {
              this.setFocus(false);
            }}
          />
        ) : (
          <input
            id={this.id}
            type={this.props.type}
            value={this.props.value}
            onChange={this.props.onChange}
            onKeyDown={this.props.onKeyDown}
            onFocus={() => this.setFocus(true)}
            onBlur={() => {
              this.setFocus(false);
            }}
          />
        )}{" "}
      </div>
    );
  }
}

LabelTexbox.defaultProps = {
  type: "text",
};
export default LabelTexbox;
