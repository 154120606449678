import gtImg from "../imgs/jury-2021-03/gokhantelkenar.jpg";
import ycImg from "../imgs/jury-2020-06/yalcincetin.png";
import goImg from "../imgs/jury-2020-06/guntekinonay.png";
let juryList = [
  {
    name: "Gökhan TELKENAR",
    q: "",
    img: gtImg,
  },
  {
    name: "Yalçın ÇETİN",
    q: "",
    img: ycImg,
  },
  {
    name: "Güntekin ONAY",
    q: "",
    img: goImg,
  },
];
export default juryList;
