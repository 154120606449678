import React, { Component, Fragment } from "react";
import errorImg from "../../imgs/error.svg";
import successImg from "../../imgs/success.svg";
export default class MessageModal extends Component {
  render() {
    return (
      <Fragment>
        <img src={this.props.status ? successImg : errorImg} />
        <h2>{this.props.message}</h2>
        <button
          type="button"
          onClick={() => {
            this.props.onClose();
          }}
        >
          Tamam
        </button>
      </Fragment>
    );
  }
}
