import React from "react";
import "../../scss/components/modalMessage.scss";
import { terms, kvkkRules } from "../../config/texts.json";

const ModalMessage = props => {
  return (
    <React.Fragment>
      {props.flag === "katilim" ? (
        <>
          <p className="modalMessage__title">{terms.title}</p>
          <p className="modalMessage__text">{terms.textA}</p>
          <div className="term-text">
            {terms.textB.map((item, index) => {
              return <p key={index}>{item}</p>;
            })}
          </div>
        </>
      ) : (
        <>
          <p className="modalMessage__title">{kvkkRules.title}</p>
          <p className="modalMessage__text">{kvkkRules.textA}</p>
          <p className="modalMessage__text">{kvkkRules.textB}</p>
        </>
      )}
    </React.Fragment>
  );
};

export default ModalMessage;
