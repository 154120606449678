import React, { Component } from "react";
import "../../scss/components/top-menu.scss";
import classNames from "classnames";
import logoImg from "../../imgs/logo.svg";
export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  toggle = (status) => {
    if (status === undefined) status = !this.state.open;
    this.setState({ open: status });
  };
  render() {
    return (
      <div className={classNames("top-menu", { opened: this.state.open })}>
        <a
          className="top-menu-hamburger"
          href="#"
          onClick={(ev) => {
            ev.stopPropagation();
            ev.preventDefault();
            var open = !this.state.open;
            this.setState({ open });
            if (typeof this.props.onToggle === "function")
              this.props.onToggle(open);
          }}
        >
          <span className="bar" />
          <span className="bar" />
          <span className="bar" />
        </a>
        <img
          src={logoImg}
          className={classNames("logo", { hide: this.props.page === -1 })}
        />
      </div>
    );
  }
}
