import Page from "./page";
import "../scss/pages/gradient-back.scss";

import React, { Component } from "react";
import classNames from "classnames";
class GradientBackPage extends Component {
  render() {
    return (
      <Page className={classNames("gradient-back", this.props.className)}>
        <div className="gradient-header-back"></div>
        {this.props.children}
        <div className="gradient-bottom-back"></div>
      </Page>
    );
  }
}
export default GradientBackPage;
