import logo1x from "./imgs/logo.png";
import logo2x from "./imgs/logo@2x.png";
import logo3x from "./imgs/logo@3x.png";
import microphone1x from "./imgs/microphone.png";
import microphone2x from "./imgs/microphone@2x.png";
import microphone3x from "./imgs/microphone@3x.png";
function getSrcSet(im1, im2, im3) {
  return { src: im1, set: `${im1} 640w,${im2} 750w,${im3} 1080w` };
}

const logo = getSrcSet(logo1x, logo2x, logo3x);
const microphone = getSrcSet(microphone1x, microphone2x, microphone3x);

export { microphone, logo };
