import React, { Component } from "react";
import "../scss/pages/jury.scss";
import Page from "./page";
import juryList from "../utils/juries";
import { jury } from "../config/texts.json";

class Jury extends Component {
  render() {
    return (
      <Page className="jury-page">
        <div className="jury-content">
          <div className="title">
            <h1>{jury.title}</h1>
          </div>
          <ul className="jury-list">
            {juryList.map((item, index) => {
              return (
                <li key={index}>
                  <div className="img">
                    <img src={item.img} />
                  </div>
                  <h3>{item.name}</h3>
                  {/* <q>{item.q}</q> */}
                </li>
              );
            })}
          </ul>
        </div>
      </Page>
    );
  }
}

export default Jury;
