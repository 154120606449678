import React, { Component } from "react";
import Page from "./page";
import "../scss/pages/howjoin.scss";
import watchImg from "../imgs/watch-icon.svg";
import recImg from "../imgs/rec-icon.svg";
import uploadImg from "../imgs/upload-video-icon.svg";
import { howjoin } from "../config/texts.json";
class HowJoin extends Component {
  render() {
    return (
      <Page className="howjoin-page">
        <div className="howjoin-content">
          <h1>{howjoin.title}</h1>
          <div className="content-columns">
            <div>
              <img src={watchImg} />
              <h3>{howjoin.content.watch.title}</h3>
              {howjoin.content.watch.content}
            </div>
            <div>
              <img src={recImg} />
              <h3>{howjoin.content.save.title}</h3>
              {howjoin.content.save.content}
            </div>
            <div>
              <img src={uploadImg} />
              <h3>{howjoin.content.upload.title}</h3>
              {howjoin.content.upload.content}
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default HowJoin;
