class GreChapcha {
  constructor(key) {
    this.key = key;
  }
  async run(action) {
    var promise = new Promise((resolve, reject) => {
      let grecaptcha = window.grecaptcha;
      console.log(this.key);
      grecaptcha.execute(this.key).then(function (token) {
        resolve(token);
      });
    });
    return promise;
  }
}

export default GreChapcha;
