import React, { Component } from "react";
import classNames from "classnames";
import Page from "./page";
import { logo } from "../images";
import "../scss/pages/home.scss";
class Home extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Page className={classNames("home", this.props.className)}>
        <img className="logo" src={logo.src} srcSet={logo.set} />
      </Page>
    );
  }
}
export default Home;
