import React, { Component } from "react";
import classNames from "classnames";
import Page from "./page";
import "../scss/pages/select-video.scss";
import { microphone } from "../images";
import VideoItem from "../components/video";
import VideoPlayer from "../components/videoplayer";
import { ModalModel, addModal, removeModal } from "../components/modal";
import { selectvideo } from "../config/texts.json";
import videos from "../config/videos.json";
class VideoModal extends Component {
  render() {
    return (
      <VideoPlayer
        url={
          // "https://strspeaker.blob.core.windows.net/matches/" +
          // "https://speaker.azureedge.net/matches/" +
          this.props.video.video
        }
        poster={this.props.video.poster}
      />
    );
  }
}
class SelectVideo extends Component {
  constructor(props) {
    super(props);
  }
  openVideo = item => {
    addModal(
      new ModalModel(
        VideoModal,
        "video-modal",
        { video: item },
        {
          outerClose: true
        }
      )
    );
  };
  render() {
    return (
      <Page className="select-video-page">
        <div className="select-video-content">
          <div className="video-page-center">
            <h1>{selectvideo.title}</h1>
            <div className="videos">
              {videos.map((item, index) => {
                return (
                  <div key={index} className="video-content">
                    <VideoItem
                      onClick={() => this.openVideo(item)}
                      poster={item.poster}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default SelectVideo;
