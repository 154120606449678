import React, { Component } from "react";
import classNames from "classnames";
import "../../scss/components/checkbox.scss";
import checkImg from "./check.svg";
class Checkbox extends Component {
  render() {
    return (
      <div className="checkbox">
        <span
          className={classNames("check", { checked: this.props.checked })}
          onClick={() => this.props.onChange(!this.props.checked)}
        >
          <img src={checkImg} />
        </span>
        <span
          className="check-label"
        >
          {this.props.children}
        </span>
      </div>
    );
  }
}
Checkbox.defaultPros = {
  onChange: () => {}
};
export default Checkbox;
