import React, { Component, Fragment } from "react";
import classNames from "classnames";
export default class SmsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: ""
    };
  }
  setCode = code => {
    let ncode = "";
    let char;
    let size = 0;
    for (let index = 0; index < code.length && size < 4; index++) {
      char = code.charAt(index);
      if (char >= "0" && char <= "9") {
        ncode += char;
        size++;
      }
    }
    this.setState({ code: ncode });
  };
  render() {
    return (
      <Fragment>
        <h2>Lütfen telefonunuza gelen doğrulama kodunu giriniz</h2>
        <input
          type="number"
          className={classNames({ error: this.state.error })}
          value={this.state.code}
          onChange={ev => this.setCode(ev.target.value)}
        />
        <button
          type="button"
          onClick={() => {
            if (this.state.code.length < 4) {
              this.setState({ error: true });
              return;
            }
            this.setState({ error: false }, () => {
              this.props.onSend(this.state.code);
            });
          }}
        >
          Gönder
        </button>
      </Fragment>
    );
  }
}
