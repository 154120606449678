import React, { Component } from "react";
import Page from "./gradientback";
import "../scss/pages/join.scss";
import LabelTextbox from "../components/labeltextbox";
import Checkbox from "../components/checkbox";
import ModalMessage from "../components/modal/modalMessage";
import SmsModal from "../components/modals/smsmodal";
import MessageModal from "../components/modals/messagemodal";
import GreChapcha from "../libs/grechapcha";
import { seasonStart, seasonEnd } from "../config/settings.json";
import {
  ModalModel,
  addModal,
  removeAll,
  openLoader,
  closeLoader,
} from "../components/modal";
import { join } from "../config/texts.json";
import classNames from "classnames";
import settings from "../config/settings.json";
import moment from "moment";
import ClosedModal from "../components/closedmodal";
import cities from "../config/cities.json";
var firstDate = new Date(seasonStart);
var lastDate = new Date(seasonEnd);
var grechapcha = new GreChapcha(settings.recaptchaSiteKey);
let replaceNames = {
  name: "FullName",
  birth: "BirthDate",
  // sex: "Gender",
  tckn: "IdentityNumber",
  email: "Email",
  phone: "PhoneNumber",
  code: "Code",
  file: "File",
  city: "city",
};
let defaults = {};
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  defaults = {
    name: "asd asdsadsad",
    birth: new Date(1990, 1, 1),
    // sex: 1,
    email: "a@bccc.com",
    phone: "05439189856",
    tckn: "46243040014",
    cond: true,
    kvkk: true,
  };
}
var errorController = {
  name: (name) => {
    var keys = name.split(" ");
    if (keys.length === 1) return "İsim kısmı en az 2 kelimeden oluşmalı!";
    if (keys.find((item) => item.length < 2)) return "En az 2 harf olmalı";
  },
  birth: (birth) => {
    if (!birth) return "Zorunlu!";
  },
  // sex: sex => {
  //   if (!sex) return "Zorunlu!";
  // },
  tckn: (tcno) => {
    if (!tcno || tcno.length < 11) return "Zorunlu!";
    var sum;
    sum =
      Number(tcno.substring(0, 1)) +
      Number(tcno.substring(1, 2)) +
      Number(tcno.substring(2, 3)) +
      Number(tcno.substring(3, 4)) +
      Number(tcno.substring(4, 5)) +
      Number(tcno.substring(5, 6)) +
      Number(tcno.substring(6, 7)) +
      Number(tcno.substring(7, 8)) +
      Number(tcno.substring(8, 9)) +
      Number(tcno.substring(9, 10));
    var strsum = String(sum);
    var eleven = strsum.substring(strsum.length, strsum.length - 1);

    if (eleven === tcno.substring(10, 11)) {
      return undefined;
    } else {
      return "Hatalı TCKN";
    }
  },
  email: (email) => {
    if (!email) return "Zorunlu!";
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var res = re.test(String(email).toLowerCase());
    if (!res) return "Hatalı Email!";
  },
  phone: (phone) => {
    if (!phone) return "Zorunlu!";
    var re = /(05){1}((0)|(3)|(4)|(5)){1}([0-9]){8}/g;
    if (!re.test(phone)) return "Hatalı Telefon!";

    return undefined;
  },
  file: (file) => {
    if (!file) return "Zorunlu!";
    if (file.size / 1024 / 1024 > settings.maxVideoSize)
      return `Maksimum dosya boyutu: ${settings.maxVideoSize}MB`;
  },
  cond: (val) => {
    return !val ? "Zorunlu!" : undefined;
  },
  kvkk: (val) => {
    return !val ? "Zorunlu!" : undefined;
  },
  city: (val) => {
    return !val ? "Zorunlu!" : undefined;
  },
};
class Join extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...{
        name: "",
        birth: "",
        // sex: 0,
        email: "",
        phone: "",
        //phone: "",
        tckn: "",
        file: undefined,
        cond: false,
        kvkk: false,
        city: undefined,
        closed: lastDate < new Date() || firstDate > new Date(),
        error: {},
      },
      ...defaults,
    };
    var diff = lastDate - new Date();
    if (diff > 0 && diff < 1000 * 60 * 60 * 24 * 3)
      setTimeout(() => {
        this.setState({ closed: true });
      }, diff);
    //addModal(new ModalModel(SmsModal));
  }
  onSend = async (code) => {
    if (this.state.closed) return;

    var token = await grechapcha.run("create");
    let data = new FormData();
    this.setState({ code }, () => {
      for (var key of Object.keys(replaceNames)) {
        if (key === "birth") {
          data.append(
            replaceNames[key],
            moment(this.state[key]).format("YYYY-MM-DD")
          );
        } else data.append(replaceNames[key], this.state[key]);
      }
      data.set("g-recaptcha-response", token);
      openLoader();
      fetch(`${settings.origin}/${settings.createSpeaker}`, {
        method: "post",
        body: data,
        headers: {
          //"Access-Control-Allow-Origin": "*"
        },
      })
        .then((resp) => {
          closeLoader();
          return resp.json();
        })
        .then((data) => {
          if (data.success) {
            this.openMessage(join.success, true);
            this.setState({
              name: "",
              birth: "",
              // sex: 0,
              email: "",
              phone: "",
              //phone: "",
              tckn: "",
              //  file: undefined,
              cond: false,
              kvkk: false,
            });
          } else this.openMessage(data.errors[0].description, false);
        })
        .catch((err) => {
          closeLoader();
          this.openMessage(join.error, false);
        });
    });
  };
  setValue = (key, value) => {
    let error = { ...this.state.error };
    error[key] = errorController[key](value);
    let state = { error };
    state[key] = value;
    this.setState(state);
  };
  submit = async (ev) => {
    ev.preventDefault();
    if (this.state.closed) return;
    var token = await grechapcha.run("sendCode");
    let error = {};
    let result;
    for (const valid of Object.keys(errorController)) {
      result = errorController[valid](this.state[valid]);
      if (result) error[valid] = result;
    }
    if (Object.keys(error).length > 0) {
      this.setState({ error });
      return;
    }
    var data = new FormData();
    data.set("phoneNumber", this.state.phone);
    data.set("g-recaptcha-response", token);
    openLoader();
    fetch(`${settings.origin}/${settings.sendSms}`, {
      method: "post",
      body: data,
      headers: {
        //"Access-Control-Allow-Origin": "*"
      },
    })
      .then((resp) => {
        closeLoader();
        return resp.json();
      })
      .then((data) => {
        if (data.success) {
          removeAll();
          this.modal = new ModalModel(SmsModal, "sms-modal", {
            onSend: this.onSend,
          });
          addModal(this.modal);
        } else this.openMessage(data.errors[0].description, false);
      })
      .catch((err) => {
        closeLoader();
        this.openMessage(join.smsError, false);
      });
  };
  openMessage = (message, status) => {
    removeAll();
    this.modal = new ModalModel(
      MessageModal,
      "message-modal",
      {
        status,
        message,
        onClose: () => {
          removeAll();
        },
      },
      { outerClose: true }
    );
    addModal(this.modal);
  };
  openModal = (flag) => {
    removeAll();
    this.modal = new ModalModel(
      ModalMessage,
      "term-modal",
      {
        flag,
      },
      { outerClose: true }
    );
    addModal(this.modal);
  };
  selectFile = () => {
    this.fileInput.click();
  };
  setFile(file) {
    if (!file) file = null;
    this.setValue("file", file);
  }

  setCity = (ev) => {
    let selectedCity = ev.target.value;
    let autoComplete = false;
    let backspace = this.state.keydown;
    if (!backspace)
      for (const city of cities) {
        if (city.toLowerCase().startsWith(ev.target.value.toLowerCase())) {
          selectedCity = city;
          autoComplete = true;
          break;
        }
      }
    if (autoComplete) {
      let start = ev.target.value.length;
      ev.target.value = selectedCity;
      ev.target.setSelectionRange(start, selectedCity.length);
      this.setValue("city", selectedCity);
    } else this.setValue("city", ev.target.value);
  };

  render() {
    return (
      <Page className="join-page">
        {this.state.closed ? (
          <ClosedModal firstDate={firstDate} lastDate={lastDate} />
        ) : (
          ""
        )}

        <div className="join-content">
          <div className="title">
            <h1>{join.title}</h1>
          </div>
          <form onSubmit={this.submit} className="join-form">
            <div
              className="form-rows"
              onTouchStart={(ev) => ev.stopPropagation()}
              onTouchEnd={(ev) => ev.stopPropagation()}
              onWheel={(ev) => ev.stopPropagation()}
            >
              <div className="row">
                <LabelTextbox
                  value={this.state.name}
                  onChange={(ev) => this.setValue("name", ev.target.value)}
                  label={join.labels.name}
                  error={this.state.error.name}
                />
              </div>
              <div className="row">
                <LabelTextbox
                  value={this.state.birth}
                  type="date"
                  maxDate={
                    new Date(
                      lastDate.getFullYear() - 18,
                      lastDate.getMonth(),
                      lastDate.getDate()
                    )
                  }
                  onChange={(ev) => this.setValue("birth", ev)}
                  label={join.labels.birth}
                  error={this.state.error.birth}
                />
              </div>
              {/* <div
              className={classNames("row radio-row", {
                error: this.state.error.sex
              })}
            >
              <label>{join.labels.sex}</label>
              <RadioList
                onChange={value => this.setValue("sex", value)}
                value={this.state.sex}
                items={[
                  {
                    label: "Kadın",
                    value: 2
                  },
                  {
                    label: "Erkek",
                    value: 1
                  }
                ]}
              />
            </div> */}
              <div className="row">
                <LabelTextbox
                  type="number"
                  value={this.state.tckn}
                  onChange={(ev) => this.setValue("tckn", ev.target.value)}
                  label={join.labels.tckn}
                  error={this.state.error.tckn}
                />
              </div>{" "}
              <div className="row">
                <LabelTextbox
                  type="email"
                  value={this.state.email}
                  onChange={(ev) => this.setValue("email", ev.target.value)}
                  label={join.labels.email}
                  error={this.state.error.email}
                />
              </div>
              <div className="row">
                <LabelTextbox
                  type="phone"
                  value={this.state.phone}
                  onChange={(ev) => this.setValue("phone", ev.target.value)}
                  label={join.labels.phone}
                  error={this.state.error.phone}
                />
              </div>
              <div className="row">
                <LabelTextbox
                  type="text"
                  value={this.state.city}
                  onKeyDown={(ev) =>
                    this.setState({ keydown: ev.keyCode === 8 })
                  }
                  onChange={(ev) => this.setCity(ev)}
                  label={join.labels.city}
                  error={this.state.error.city}
                ></LabelTextbox>
              </div>
              <div className="row file-row">
                <h4>{join.labels.upload}</h4>
                <button
                  className="select-file"
                  type="button"
                  onClick={this.selectFile}
                >
                  <input
                    type="file"
                    ref={(ref) => (this.fileInput = ref)}
                    accept="video/mp4,video/x-m4v,video/*"
                    onChange={(ev) => {
                      this.setFile(ev.target.files[0]);
                    }}
                    style={{ display: "none" }}
                  />
                  {join.labels.selectfile}
                </button>
                {this.state.error.file ? (
                  <div className="file-message error">
                    {this.state.error.file}
                  </div>
                ) : (
                  <div className="file-message">
                    {this.state.file
                      ? this.state.file.name
                      : `Henüz Dosya Seçilmedi Maksimum dosya boyutu ${settings.maxVideoSize}MB`}
                  </div>
                )}
              </div>
              <div
                className={classNames("row check-row", {
                  error: this.state.error.cond,
                })}
              >
                <Checkbox
                  checked={this.state.cond}
                  onChange={(value) => this.setValue("cond", value)}
                >
                  <span
                    onClick={(ev) => {
                      ev.preventDefault();
                      this.openModal("katilim");
                    }}
                  >
                    Katılım koşullarını
                  </span>{" "}
                  okudum ve kabul ediyorum.
                </Checkbox>
              </div>
              <div
                className={classNames("row check-row", {
                  error: this.state.error.kvkk,
                })}
              >
                <Checkbox
                  checked={this.state.kvkk}
                  onChange={(value) => this.setValue("kvkk", value)}
                >
                  <span
                    onClick={(ev) => {
                      ev.preventDefault();
                      this.openModal();
                    }}
                  >
                    KVKK hakkında
                  </span>
                  bilgilendirme metnini okudum ve kabul ediyorum.
                </Checkbox>
              </div>
              <div className="row join-row">
                <button>{join.labels.send}</button>
              </div>
            </div>
          </form>
        </div>
      </Page>
    );
  }
}

export default Join;
