import React, { Component, Fragment } from "react";
import classNames from "classnames";
import "../../scss/components/modal.scss";
let container;

let Modal = props => {
  return (
    <div
      onClick={props.outerClick}
      className={classNames("modal", props.className)}
    >
      <div onClick={ev => ev.stopPropagation()} className="modal-content">
        {props.children}
      </div>
    </div>
  );
};

let Loader = props => {
  return <div className="lds-dual-ring"></div>;
};

class ModalContainer extends Component {
  constructor(props) {
    super(props);
    container = this;
    this.state = {
      modals: [],
      loader: false
    };
  }
  add = modal => {
    var modals = [...this.state.modals, modal];
    this.setState({ modals });
  };
  remove = modal => {
    var modals = [...this.state.modals];
    let index = modals.indexOf(modal);
    if (index >= 0) modals.splice(index, 1);
    this.setState({ modals });
  };
  removeAll = () => {
    this.setState({ modals: [] });
  };
  openLoader = () => {
    this.setState({ loader: true });
  };
  closeLoader = () => {
    this.setState({ loader: false });
  };
  render() {
    return (
      <Fragment>
        <div className="modals">
          {this.state.modals.map((item, index) => {
            let Comp = item.component;
            return (
              <Modal
                key={index}
                outerClick={() => {
                  if (item.options.outerClose) this.remove(item);
                }}
                className={classNames(item.className, `modal-${index}`)}
              >
                <Comp {...item.props} />
              </Modal>
            );
          })}
        </div>
        {this.state.loader ? (
          <div className="loader-container">
            <Loader />
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

class ModalModel {
  constructor(component, className, props, options = {}) {
    this.className = className;
    this.props = props;
    this.component = component;
    this.options = options;
  }
}

let addModal = item => {
  container.add(item);
};
let removeModal = item => {
  container.remove(item);
};
let removeAll = () => {
  container.removeAll();
};
let openLoader = () => {
  container.openLoader();
};
let closeLoader = () => {
  container.closeLoader();
};

export {
  ModalContainer,
  ModalModel,
  addModal,
  removeModal,
  Modal,
  removeAll,
  openLoader,
  closeLoader
};
