import React, { Component } from "react";
import "../../scss/components/left-menu.scss";
import classNames from "classnames";

class LeftMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }
  toggle = status => {
    if (status === undefined) status = !this.state.open;
    this.setState({ open: status });
  };
  setIndex = (ev, index) => {
    ev.stopPropagation();
    ev.preventDefault();
    this.props.onPageChange(index);
  };
  render() {
    return (
      <React.Fragment>
        <ul
          className={classNames("slide-dots", {
            hidden: this.props.page === -1
          })}
        >
          {this.props.pages.map((item, index) => {
            return (
              <li
                key={index}
                className={classNames({
                  active: index === this.props.page
                })}
                onClick={ev => this.setIndex(ev, index)}
              >
                <a href="#" onClick={ev => ev.preventDefault()} />
              </li>
            );
          })}
        </ul>
        <div className={classNames("left-menu", { opened: this.state.open })}>
          <ul className="menu-items">
            {this.props.pages.map((item, index) => {
              return (
                <li key={index} onClick={ev => this.setIndex(ev, index)}>
                  <a href="#" onClick={ev => ev.preventDefault()}>
                    {item.text}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}
LeftMenu.defaultProps = {
  onPageChange: () => {}
};
export default LeftMenu;
