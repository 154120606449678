import React, { Component, Fragment } from "react";
import { Modal } from "../modal";
import "../../scss/components/closed-modal.scss";
import moment from "moment";
import "moment/locale/tr";
class ClosedModal extends Component {
  render() {
    let firstDate = this.props.firstDate;
    let lastDate = this.props.lastDate;
    return (
      <div className="modals closed-modals">
        <Modal>
          {firstDate > new Date() ? (
            <Fragment>
              Başvurular{" "}
              <b>{moment(firstDate).lang("tr").format("DD MMMM YYYY HH:mm")}</b>{" "}
              itibariyle başlayacaktır.
            </Fragment>
          ) : (
            ""
          )}
          {lastDate < new Date() ? (
            <Fragment>
              Başvurular{" "}
              <b>{moment(lastDate).lang("tr").format("DD MMMM YYYY HH:mm")}</b>{" "}
              itibariyle sona ermiştir.
            </Fragment>
          ) : (
            ""
          )}
          <br />
          İlginiz için teşekkür ederiz.
        </Modal>
      </div>
    );
  }
}

export default ClosedModal;
