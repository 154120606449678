//https://eu-s3.dt.ercdn.com/s3/progressive/c/-l/ligtvcomtr_tauri_ozt_20192020_13_ala_ank_30kasim.mp4?st=V2_I8eZFez_WrXtjuLLXiA&e=1576693566

import React, { Component } from "react";
import "../../scss/components/video-player.scss";
import classNames from "classnames";

//HTML5
/*class VideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className={classNames("video-player", this.props.VideoPlayer)}>
        <video
          src={this.props.url}
          poster={this.props.poster}
          autoPlay
          controls
          style={{ width: "100%" }}
        ></video>
      </div>
    );
  }
}

export default VideoPlayer;*/

//bitmovin
/*
import { Player } from "bitmovin-player";
var bitmovinConfig = {
  key: "047ddde8-7d3f-4355-959a-4dc51ec5b10e"
};
class BitmovinPlayer extends React.Component {
  state = {
    player: null
  };
  playerConfig = {
    key: "047ddde8-7d3f-4355-959a-4dc51ec5b10e"
  };
  componentDidMount() {
    this.setupPlayer();
  }
  componentWillUnmount() {
    this.destroyPlayer();
  }
  setupPlayer = p => {
    if (!p || this.state.player) return;
    const player = new Player(p, this.playerConfig);
    player
      .load({
        progressive: this.props.url,
        poster: this.props.poster
      })
      .then(
        () => {
          this.setState({
            ...this.state,
            player
          });
          player.play();
          console.log("Successfully loaded source");
        },
        () => {
          console.log("Error while loading source");
        }
      );
  };
  destroyPlayer = () => {
    if (this.state.player != null) {
      this.state.player.destroy();
      this.setState({
        ...this.state,
        player: null
      });
    }
  };
  render() {
    return <div ref={ref => this.setupPlayer(ref)} />;
  }
}

export default BitmovinPlayer;*/
import { Player } from "video-react";
import "video-react/styles/scss/video-react.scss"; // or import scss

export default props => {
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  if (iOS)
    return (
      <video
        className="ios-video"
        autoPlay
        controls
        poster={props.poster}
        src={props.url}
      ></video>
    );
  return <Player autoPlay playsInline poster={props.poster} src={props.url} />;
};
