import React, { Component } from "react";
import "../scss/pages/term.scss";
import Page from "./gradientback";
import { terms } from "../config/texts.json";

class Term extends Component {
  stop = ev => {
    ev.stopPropagation();
  };
  render() {
    return (
      <Page className="term-page">
        <div className="absolute-content">
          <div className="term-content">
            <div className="title">
              <h1>{terms.title}</h1>
            </div>

            <div
              onWheel={this.stop}
              onTouchStart={this.stop}
              className="term-text"
              id="term-text"
            >
              {terms.textB.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default Term;
