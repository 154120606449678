import React, { Component } from "react";
import classNames from "classnames";
import scrollImg from "../imgs/scroll-down.svg";
import "../scss/pages/container.scss";
class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getStyle = () => {
    if (!this.state.touch) return undefined;
    return {
      transform: `translateY(${this.state.end - this.state.start}px)`,
    };
  };
  render() {
    return (
      <div
        className={classNames("container", this.props.className)}
        onWheel={(ev) => {
          var value = ev.deltaY > 0 ? 1 : ev.deltaY < 0 ? -1 : 0;
          if (value === 0) return;
          if (this.wheel) return;
          this.wheel = true;
          this.props.onPageChangeRequest(value);
          window.setTimeout(() => {
            this.wheel = false;
          }, 1000);
        }}
        onTouchStart={(ev) => {
          var start = ev.touches[0].clientY;
          this.setState({ start, touch: true, end: start });
        }}
        onTouchMove={(ev) => {
          var end = ev.touches[0].clientY;
          this.setState({ end });
        }}
        onTouchEnd={(ev) => {
          this.setState({ touch: false });
          var diff = this.state.start - this.state.end;
          diff = Math.round(
            diff / window.innerHeight + (diff > 0 ? 0.5 : -0.5)
          );
          this.props.onPageChangeRequest(diff);
        }}
      >
        <div className="touch-container" style={this.getStyle()}>
          <div
            className={classNames(
              "container-content",
              this.props.page === -1 ? undefined : `page-${this.props.page}`
            )}
          >
            {this.props.children}
          </div>
        </div>
        <div
          className={classNames("scroll-img", {
            hidden: this.props.page == this.props.pageSize - 1,
          })}
        >
          <a
            href="#"
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
              this.props.onPageChangeRequest(1);
            }}
          >
            <img src={scrollImg} />
          </a>
        </div>
      </div>
    );
  }
}
export default Container;
