import React, { Component } from "react";
import classNames from "classnames";
class Page extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={classNames("page", this.props.className)}>
        {this.props.children}
      </div>
    );
  }
}
export default Page;
