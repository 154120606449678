import React, { Component } from "react";
import classNames from "classnames";
import Page from "./gradientback";
import "../scss/pages/microphone-page.scss";
import { microphone } from "../images";

import { microphone as content } from "../config/texts.json";
class MicrophonePage extends Component {
  render() {
    return (
      <Page className="microphone-page">
        <div className="microphone-content">
          <div className="header">{content.title}</div>
          <div className="content">
            {content.content}
            <br></br>
            <br></br>
            <div className="example-video">
              <a
                href="https://tr.beinsports.com/haber/kolaysa-anlata-video-yuklemek-cok-kolay"
                target="_blank"
              >
                {" "}
                <span>&nbsp;</span> Örnek Anlatım İçin Tıklayınız!
              </a>
            </div>
          </div>
          <div className="image">
            <img srv={microphone.src} srcSet={microphone.set} />
          </div>
        </div>
      </Page>
    );
  }
}

export default MicrophonePage;
