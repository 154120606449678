import React, { Component } from "react";
import "../../scss/components/video.scss";
import classNames from "classnames";
import playImage from "../../imgs/play.svg";
class VideoItem extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div
        onClick={this.props.onClick}
        className={classNames("video-item", this.props.className)}
      >
        <img className="poster" src={this.props.poster} />
        <a className="play" href="#" onClick={ev => ev.preventDefault()}>
          <img src={playImage} className="play-button" />
        </a>
      </div>
    );
  }
}
VideoItem.defaultProps = {};
export default VideoItem;
